import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import StoreContext from '../../context/store-context';

const ThanksPage = () => {
  const context = useContext(StoreContext);
  const {addVariantToCart, clearCart, store: { checkout } } = context;
  useEffect(() => {
    if (checkout.lineItems.length !== 0) {
      clearCart();
    }
  }, [clearCart, checkout]);
  return (
    <>
      <h1>Thank you for your order!</h1>
      <Link to='/'>Go Back to Hicksville</Link>
    </>
  );
};

export default ThanksPage;
